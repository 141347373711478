import {Button,Tabs,Tab,Card,Collapse,CollapseItem,
    DatetimePicker,Stepper,Divider,Empty,Grid,GridItem,Tag,Popup,Field,
    CellGroup,Loading,Sidebar,SidebarItem,Toast,Overlay,Row,Col,Dialog} from 'vant';
const vant = {
    install: function (Vue) {
        Vue.use(Tab);
        Vue.use(Button);
        Vue.use(Tabs);
        Vue.use(Card);
        Vue.use(Collapse);
        Vue.use(CollapseItem);
        Vue.use(DatetimePicker);
        Vue.use(Stepper);
        Vue.use(Divider);
        Vue.use(Empty);
        Vue.use(Grid);
        Vue.use(GridItem);
        Vue.use(Tag);
        Vue.use(Popup);
        Vue.use(Field);
        Vue.use(CellGroup);
        Vue.use(Loading);
        Vue.use(Sidebar);
        Vue.use(SidebarItem);
        Vue.use(Toast);
        Vue.use(Overlay);
        Vue.use(Row);
        Vue.use(Col);
        Vue.use(Dialog);
    }
}
export default vant
