import Vue from "vue";
import  VueRouter from "vue-router"
import axios from 'axios';
Vue.use(VueRouter)
const routes = [
    {
      path:'/',
      component:()=>import('../login.vue')
    },
    {
        path:'/home',
        component:()=>import('../home.vue')
    },
    {
        path:'/login',
        component:()=>import('../login.vue')
    }
]

const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {
    if(to.path === '/login') {
      next()
    } else {
      let token = localStorage.getItem('token')
      token ? next() : next('/login')
    }
  });
  
  axios.interceptors.response.use(
    response => {
      if (response.data.code === 4001 || response.data.code === 4002 || response.data.code === 4003) {
        router.push('/login');
      } else {
        return response;
      }
    },
    error => {
      return Promise.reject(error);
    }
  );

export default router
