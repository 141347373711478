import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from '@/ui'
import config from './config';
// 使用vant
Vue.use(vant)
Vue.config.productionTip = false
Vue.prototype.$apiBaseUrl = config.baseApi;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')